import React, { FC, lazy, PropsWithChildren } from 'react';
import { useSession } from './sessionProvider';
import { CenteredSpinner } from 'components/CenteredSpinner';

const SignInModal = lazy(() => import('screens/Authentication/SignIn'));

export const RequireAuthentication: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isReloading } = useSession();

  if (isReloading) {
    return <CenteredSpinner debugName="RequireAuthentication/isReloading" />;
  }

  if (!isAuthenticated) {
    return <SignInModal />;
  }

  return <>{children}</>;
};
