import { Button, Link, useInterval, VStack } from '@cardboard-ui/react';
import React, { FC, lazy, useEffect, useLayoutEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';
import { RequireAuthentication } from 'utils/RequireAuthentication';
import {
  asterisk,
  CALENDAR_PATH,
  CANONICAL_ITEM_PARAM,
  CANONICAL_PREFIXES,
  CHANGE_PASSWORD_PATH,
  CHAT_PATH,
  defaultTenantLandingPath,
  FAVOURITES_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  MEMBER_DIRECTORY_PATH,
  MEMBER_PATH,
  MORE_PATH,
  NOTIFICATIONS_PATH,
  pathParam,
  PRIVATE_VAULT_PATH,
  RESET_PASSWORD_PATH,
  SETUP_COMPLETE_PATH,
  SETUP_TENANT_CONFIRMATION_PATH,
  SETUP_TWO_FACTOR_PATH,
  SIGN_IN_PATH,
  SIGN_IN_TWO_FACTOR_PATH,
  SIGN_IN_WITH_EMAIL_TOKEN_PATH,
  SIGN_IN_WITH_GLOBAL_TOKEN_ACCOUNT_SELECT_PATH,
  SIGN_IN_WITH_GLOBAL_TOKEN_PATH,
  SIGN_IN_WITH_SWITCH_TOKEN_PATH,
  SIGN_UP_COMPLETE_PATH,
  SIGN_UP_CONFIRMATION_PATH,
  SIGN_UP_PATH,
  SPACES_PATH,
  START_PATH,
  STATISTICS_PATH,
  TERMS_AND_CONDITIONS_PATH,
  TODO_PATH,
  VAULTS_PATH,
  VERIFY_EMAIL_PATH,
} from 'utils/routes';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { t } from '@lingui/macro';
import { FolderViewModeProvider } from 'components/FolderViewer/FolderViewModeProvider';
import useFetchKey from 'hooks/useFetchKey';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';
import { SidecarProvider } from 'providers/SidecarProvider';
import { UnreadChatMessagesProvider } from 'providers/UnreadChatMessagesProvider';
import { UnreadNotificationsProvider } from 'providers/UnreadNotificationsProvider';
import { CalendarViewProvider } from 'screens/Calendar/providers';
import { authenticatedHttpRequest } from 'utils/http';
import { useSession } from 'utils/sessionProvider';
import CanonicalRedirect from './components/CanonicalRedirect';
import SignInFromEmail from 'screens/Authentication/SignInFromEmail';
import SignInFromGlobalEmailToken from 'screens/Authentication/SignInFromGlobalEmailToken';
import SignInFromSwitch from 'screens/Authentication/SignInFromSwitch';
import SignInWithTokenAccountSelect from 'screens/Authentication/SignInWithTokenAccountSelect';
import { ItemViewerNavigationProvider } from './screens/ItemViewer/ItemViewerNavigationProvider';
import NoTenant from './screens/NoTenant';
import { NotFoundContent } from './screens/NotFound';
import isRunningWithoutDomain from 'utils/isRunningWithoutDomain';

export const allowNonTenantAuthentication = isRunningWithoutDomain;

const PDFTronPreloader = lazy(
  () =>
    import(
      './screens/ItemViewer/FileRenderer/PDFTronFileRenderer/PDFTronPreloader'
    ),
);

const DesktopLayout = lazy(() => import('./components/Layout.Desktop'));
const MobileLayout = lazy(() => import('./components/Layout.Mobile'));

const ForgotPassword = lazy(
  () => import('screens/Authentication/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import('screens/Authentication/ResetPassword'),
);
const SignUp = lazy(() => import('apps/TenantApp/screens/setup/SignUp'));
const VerifyEmail = lazy(() => import('apps/TenantApp/screens/VerifyEmail'));
const ChangePassword = lazy(
  () => import('screens/Authentication/ChangePassword'),
);
const TermsAndConditions = lazy(
  () => import('apps/TenantApp/screens/TermsAndConditions'),
);
const AccountSwitcher = lazy(
  () => import('apps/TenantApp/screens/AccountSwitcher'),
);

const CreateTenant = lazy(() => import('screens/CreateTenant'));
const SetupTenant = lazy(
  () => import('apps/TenantApp/screens/setup/SetupTenant'),
);
const SetupTenantComplete = lazy(
  () => import('apps/TenantApp/screens/setup/SetupTenantComplete'),
);
const SignInScreen = lazy(() => import('screens/Authentication/SignIn'));

// const Home = loadable(() => import('./screens/Home'), loadingPageFallback);
const Home = lazy(() => import('./screens/Home'));
const Start = lazy(() => import('./screens/Start'));
const Todos = lazy(() => import('apps/TenantApp/screens/Todo'));
const SignUpComplete = lazy(
  () => import('apps/TenantApp/screens/setup/SignUpComplete'),
);
const SetupTwoFactor = lazy(
  () => import('apps/TenantApp/screens/setup/SetupTwoFactor'),
);
const SpaceRouter = lazy(() => import('./screens/Space'));
const MemberProfile = lazy(() => import('./screens/MemberProfile'));
const VaultsRouter = lazy(() => import('./screens/Vaults'));
const PrivateVault = lazy(() => import('screens/PrivateVault'));
const Calendar = lazy(() => import('apps/TenantApp/screens/Calendar'));
const Favourites = lazy(() => import('apps/TenantApp/screens/Favourites'));
const MySettings = lazy(() => import('apps/TenantApp/screens/MySettings'));
const ItemViewer = lazy(() => import('apps/TenantApp/screens/ItemViewer'));
const Chat = lazy(() => import('apps/TenantApp/screens/Chat'));
const Notifications = lazy(() => import('./screens/Notifications'));
const OrgSettings = lazy(
  () => import('apps/TenantApp/screens/OrganisationSettings'),
);
const SpaceSettings = lazy(
  () => import('apps/TenantApp/screens/Space/screens/Settings'),
);
const SearchModal = lazy(() => import('apps/TenantApp/components/SearchModal'));
const AnnouncementViewer = lazy(
  () => import('apps/TenantApp/screens/AnnouncementViewer'),
);
const I18nTest = lazy(() => import('utils/i18n/TestComponent'));
const UnleashTest = lazy(() => import('utils/unleash/TestComponent'));
const Statistics = lazy(() => import('apps/TenantApp/screens/Statistics'));
const MemberDirectory = lazy(() => import('screens/MemberDirectory'));
const More = lazy(() => import('apps/TenantApp/screens/More'));

const TenantAppRoutes = () => {
  const { isGuest } = useSession();

  return (
    <Routes>
      <Route index element={<IndexRedirect />} />
      <Route path={SIGN_IN_PATH} element={<SignInScreen />} />
      <Route
        path={SIGN_IN_TWO_FACTOR_PATH}
        element={<Navigate to={defaultTenantLandingPath(isGuest)} />}
      />
      <Route
        path={SIGN_IN_WITH_GLOBAL_TOKEN_PATH}
        element={<SignInFromGlobalEmailToken />}
      />
      <Route
        path={SIGN_IN_WITH_GLOBAL_TOKEN_ACCOUNT_SELECT_PATH}
        element={<SignInWithTokenAccountSelect />}
      />
      <Route element={<SignUpGuard />}>
        <Route path={SIGN_UP_PATH} element={<CreateTenant />} />
      </Route>
      <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
      <Route element={<PublicLayout />}>
        <Route
          path={SIGN_IN_WITH_EMAIL_TOKEN_PATH}
          element={<SignInFromEmail />}
        />
        <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
        <Route
          path={SIGN_IN_WITH_SWITCH_TOKEN_PATH}
          element={<SignInFromSwitch />}
        />
        <Route path={SIGN_UP_CONFIRMATION_PATH} element={<SignUp />} />
        <Route
          path={SETUP_TENANT_CONFIRMATION_PATH}
          element={<SetupTenant />}
        />
        <Route
          path={TERMS_AND_CONDITIONS_PATH}
          element={<TermsAndConditions />}
        />
        <Route path={SETUP_TWO_FACTOR_PATH} element={<SetupTwoFactor />} />
        <Route path={VERIFY_EMAIL_PATH} element={<VerifyEmail />} />
      </Route>

      <Route path="/__test/unleash" element={<UnleashTest />} />
      <Route path="/__test/i18n" element={<I18nTest />} />

      <Route element={<TenantGuard />}>
        <Route element={<AuthenticatedLayout />}>
          <Route path={SIGN_UP_COMPLETE_PATH} element={<SignUpComplete />} />
          <Route path={SETUP_COMPLETE_PATH} element={<SetupTenantComplete />} />
          <Route path={CHANGE_PASSWORD_PATH} element={<ChangePassword />} />

          <Route element={<ApplicationLayout />}>
            {CANONICAL_PREFIXES.map((prefix) => (
              <Route
                key={prefix}
                path={`${prefix}/${pathParam(CANONICAL_ITEM_PARAM)}`}
                element={<CanonicalRedirect prefix={prefix} />}
              />
            ))}
            <Route path={HOME_PATH} element={<Home />} />
            <Route path={START_PATH} element={<Start />} />
            <Route path={NOTIFICATIONS_PATH} element={<Notifications />} />
            <Route path={FAVOURITES_PATH} element={<Favourites />} />
            <Route path={STATISTICS_PATH} element={<Statistics />} />
            <Route path={MEMBER_DIRECTORY_PATH} element={<MemberDirectory />} />

            <Route path={asterisk(SPACES_PATH)} element={<SpaceRouter />} />
            <Route path={asterisk(VAULTS_PATH)} element={<VaultsRouter />} />
            <Route path={PRIVATE_VAULT_PATH} element={<PrivateVault />} />
            <Route path={MORE_PATH} element={<More />} />
            <Route path={TODO_PATH} element={<Todos />} />
            <Route path={MEMBER_PATH} element={<MemberProfile />} />
            <Route path={asterisk(CALENDAR_PATH)} element={<Calendar />} />
            <Route path={asterisk(CHAT_PATH)} element={<Chat />} />
            <Route path="__empty_layout" element={<div>Empty</div>} />
            <Route path="*" element={<NotRecognisedPath />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default TenantAppRoutes;

const PublicLayout = () => <Outlet />;

const AuthenticatedLayout = () => (
  <RequireAuthentication>
    <SessionPulser />
    <SidecarProvider>
      <UnreadNotificationsProvider>
        <UnreadChatMessagesProvider>
          <CalendarViewProvider>
            <FolderViewModeProvider>
              <ItemViewerNavigationProvider>
                <ItemViewer />
                <AnnouncementViewer />
                <MySettings />
                <OrgSettings />
                <SpaceSettings />
                <AccountSwitcher />
                <SearchModal />
                <Outlet />
                <PDFTronPreloader />
              </ItemViewerNavigationProvider>
            </FolderViewModeProvider>
          </CalendarViewProvider>
        </UnreadChatMessagesProvider>
      </UnreadNotificationsProvider>
    </SidecarProvider>
  </RequireAuthentication>
);

const PULSE_INTERVAL = 1000 * 60 * 5;
const PULSE_CHECK_INTERVAL = PULSE_INTERVAL / 5;
const SessionPulser = () => {
  const [lastPulse, setLastPulse] = React.useState<number>(0);
  const [shouldCheckPulse, setShouldCheckPulse] = useFetchKey();

  useInterval(setShouldCheckPulse, PULSE_CHECK_INTERVAL);

  useEffect(() => {
    if (lastPulse < Date.now() - PULSE_INTERVAL) {
      pulse().then((r) => {
        if (r?.status === 200) {
          setLastPulse(shouldCheckPulse);
        }
      });
    }
  }, [shouldCheckPulse, lastPulse]);

  return null;
};

const pulse = async () => {
  const isActive = Capacitor.isNativePlatform()
    ? (await App.getState()).isActive
    : !document.hidden;

  if (isActive) {
    return await authenticatedHttpRequest('/pulse', {
      method: 'POST',
    });
  }
};

const TenantGuard: FC = () => {
  const { tenant } = useSession();

  if (tenant) {
    return <Outlet />;
  } else {
    return <NoTenant />;
  }
};

const SignUpGuard: FC = () => {
  const { tenant } = useSession();

  // @TODO Remove exception on Jan 1st 2023
  // For a short time we will allow "app." based sign up as well.
  // This is so we won't break old links out in the wild.
  const isNewDomain =
    window.location.hostname.startsWith('new.') ||
    window.location.hostname.startsWith('signup.') ||
    window.location.hostname.startsWith('app.');

  if (!tenant && isNewDomain) {
    return <Outlet />;
  } else {
    return (
      <ScreenWithSingleSection>
        <VStack spacing={5}>
          <NotFoundContent />
        </VStack>
      </ScreenWithSingleSection>
    );
  }
};

const IndexRedirect = () => {
  const { tenant, isAuthenticated, isGuest } = useSession();

  if (isAuthenticated) {
    return <Navigate to={defaultTenantLandingPath(isGuest)} />;
  }

  if (tenant || allowNonTenantAuthentication()) {
    return <Navigate to={SIGN_IN_PATH} />;
  }

  return <NoTenant />;
};

const NotRecognisedPath = () => {
  // This happens in the context of an Authenticated tenant.
  return (
    <NotFoundContent>
      <Button as={Link} to="/">{t`Go home`}</Button>
    </NotFoundContent>
  );
};

const ApplicationLayout = () => {
  const activeLayout = useActiveLayout();
  const Shell = activeLayout === Layout.DESKTOP ? DesktopLayout : MobileLayout;

  useLayoutEffect(() => {
    // eslint-disable-next-line no-console
    console.debug(
      'Active layout:',
      activeLayout === Layout.DESKTOP ? 'Desktop' : 'Mobile',
    );
  }, []);

  return <Shell />;
};
