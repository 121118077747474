import React, { useEffect } from 'react';
import { CUSTOM_THEME } from 'theme';
import {
  Button,
  ButtonGroup,
  ChakraProvider,
  Circle,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@cardboard-ui/react';
import { LayoutProvider } from 'utils/LayoutProvider';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';
import { createIcon } from 'components/icons/createIcon';
import * as light from '@fortawesome/pro-light-svg-icons';
import { useNetworkStatus } from 'hooks/useNetworkStatus';

const NetworkDisconnectedIcon = createIcon(light.faWifiSlash);
const RetryIcon = createIcon(light.faRotate);

export default ({ refresh }: { refresh: () => void }) => {
  const networkStatus = useNetworkStatus();

  useEffect(() => {
    if (networkStatus?.connected) {
      refresh();
    }
  }, [networkStatus]);

  return (
    <ChakraProvider theme={CUSTOM_THEME} resetCSS>
      <LayoutProvider>
        <ScreenWithSingleSection>
          <VStack spacing={5}>
            <Circle bg="blue.500" size="90px">
              <NetworkDisconnectedIcon size="3x" color="white" />
            </Circle>

            <VStack spacing={5}>
              <Heading fontSize="xl">
                Oops, it seems you've lost connection to the internet.
              </Heading>
              <VStack>
                <Text>Here's what you can try:</Text>
                <UnorderedList style={{ marginInlineStart: '1.5rem' }}>
                  <ListItem>
                    Check Wi-Fi: Ensure your Wi-Fi is on and{' '}
                    <strong>you're connected.</strong>
                  </ListItem>
                  <ListItem>
                    Mobile Data: If using a mobile device, verify that{' '}
                    <strong>data is enabled.</strong>
                  </ListItem>
                  <ListItem>
                    Network Settings: Review your device's{' '}
                    <strong>network settings</strong> for any issues.
                  </ListItem>
                </UnorderedList>
              </VStack>

              <VStack spacing={0}>
                <Text fontWeight="bold">
                  Once your connection is restored, please try again.
                </Text>
                <Text fontSize="sm">
                  We'll be here once you're back online. Thank you for your
                  patience.
                </Text>
              </VStack>
            </VStack>

            <ButtonGroup>
              <Button leftIcon={<RetryIcon />} onClick={refresh}>
                Try Again
              </Button>
            </ButtonGroup>
          </VStack>
        </ScreenWithSingleSection>
      </LayoutProvider>
    </ChakraProvider>
  );
};
