import React from 'react';
import { MaintenanceIcon } from 'components/icons';
import {
  Button,
  ChakraProvider,
  Circle,
  Heading,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { CUSTOM_THEME } from 'theme';
import { LayoutProvider } from 'utils/LayoutProvider';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';

export default () => {
  const refresh = () => window.resetAppState();
  return (
    <ChakraProvider theme={CUSTOM_THEME} resetCSS>
      <LayoutProvider>
        <ScreenWithSingleSection>
          <VStack spacing={5}>
            <Circle bg="blue.300" size="90px">
              <MaintenanceIcon size="3x" color="white" />
            </Circle>
            <VStack>
              <Heading fontSize="xl">
                The site is undergoing maintenance
              </Heading>
              <Text textAlign="center">We will be back soon...</Text>
            </VStack>
            <Button onClick={refresh}>Reload</Button>
          </VStack>
        </ScreenWithSingleSection>
      </LayoutProvider>
    </ChakraProvider>
  );
};
